.post-nav {
	visibility: hidden;
}

.wrapper-load-more{
	margin-top: 60px;
	text-align: center;
	
}
#infscr-loading{
	font-size: 15px;
	line-height: 25px;
	font-weight: 400;
	color: #fff;
}