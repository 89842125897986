body{
	font-family: 'Source Sans Pro', sans-serif;
	color: #000;
	position: relative;
	margin: 0 15px;
}

.page-wrapper{
	background-color: transparent;
	padding-bottom: 80px;
	@media(min-width: @screen-sm-min) {
		width: 630px;
	}
	// margin-right: 15px;
}

a:hover{
	text-decoration: none;
}