.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	visibility: hidden;
	opacity: 0;
	transform: scale(0.8);
	transform-origin: center center;
	transition: opacity .3s, visibility .15s, transform .5s;
	&.active {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}
}

.close-overlay--swiper{
	background-image: url(../../dist/images/cross.svg);
	height: 28px;
	width: 28px;
	background-repeat: no-repeat;
	display: inline-block;
}

.cross{
	height: 28px;
	width: 28px;
}

// Quote
.overlay-quote {
	overflow-y: auto;
	padding-top: 30px;
	padding-bottom: 100px;
}

.quote-content-total__number{
	text-align: left;
	color: #fff;
	font-size: 15px;
	line-height: 25px;
	font-weight: 300;
	border-top: 3px dashed #000;
	margin-left: 15px;
	margin-right: 15px;
	padding-top: 10px;
	@media(min-width: @screen-sm-min) {
		.make-sm-column(4);
		margin-left: 0;	
		padding-left: 0;
		padding-right: 0;
	}
}

.quote-content-total__title{
	.make-sm-column(12);
	text-align: center;
	font-size: 40px;
	line-height: 30px;
	font-weight: 300;
	font-style: italic;
	padding-bottom: 25px;
	color: #000;
	text-transform: lowercase;
	@media(min-width: @screen-sm-min) {
		font-size: 100px;
		line-height: 90px;
	}
}

.quote-content-total{
	.make-sm-column(8);
	.make-sm-column-offset(2);
	top: 30px;
	margin: 0 auto;
	font-size: 18px;
	line-height: 25px;
	font-style: italic;
	font-weight: 600;
	color: #000;
	@media(min-width: @screen-sm-min) {
		font-size: 30px;
		line-height: 40px;
	}
}

.close-wrapper{
	.make-sm-column(12);
	margin-top: 70px;
	position: relative;
	width: 100%;
	height: 40px;
	text-align: center;
}
// Einde quote


// Swiper
.swiper-content-wrapper{
	position: relative;
    transform: translateY(-50%);
    top: 50%;
}

.swiper-container{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 80px;
	width: 100%;
}

.swiper-slide, .swiper-lazy{
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center; 
}

.swipernav{
	position: absolute;
	height: 80px;
    padding-top: 10px;
	width: 100%;
	bottom: 10px;
	padding-left: 24px;
	color: #fff;
	display: inline-block;
	z-index: 3000;
}

.swiper-pagination-custom{
	position: relative;
	display: inline-block;
	width: auto;
	height: auto;
	padding-right: 25px;
	padding-left: 25px;
	top: 0;
	font-size: 15px;
	line-height: 25px;
	font-weight: 600;
}

.swiper-description{
	position: relative;
	display: block;
	font-size: 15px;
	line-height: 22px;
	font-weight: 600;
	min-height: 25px;
}

.swiper-button-next, .swiper-button-prev {
    position: relative;
    display: inline-block;
    fill: #fff;
    top: 0;
    height: 28px;
    background-repeat: no-repeat;
}

.swiper-button-next{
	background-image: url(../../dist/images/arrowfurther.svg);
	margin-right: 20px;
}

.swiper-button-prev{
	background-image: url(../../dist/images/arrowback.svg);
}

.total{
	font-size: 15px;
	line-height: 18px;
	font-weight: 400;
	position: relative;
    display: block;
}

.current{
	font-size: 15px;
	line-height: 18px;
	font-weight: 700;
	position: relative;
    display: block;
}

.swiper-slide__caption {
	display: none;
}
// Einde swiper


 // Video
.overlay-video__content {
	position: absolute;
	top: 0;
	bottom: 50px;
	left: 0;
	right: 0;
	width: 100%;
}

.overlay-video__video {
	max-width: 1460px;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.videonav{
	text-align: center;
	position: absolute;
	height: 50px;
	width: 100%;
	bottom: 0px;
	left: 0;
}

.video-cross{
	height: 28px;
	width: 28px;
}