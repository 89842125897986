.header{
	.make-sm-column(6);
	.make-sm-column-offset(6);
	padding: 0;
}

.header-content{
	padding: 0;
	a{
		color: #000;
		&:hover{
			color: rgb(200,0,0);
		}
		&:before{
			content:"\2192 ";
			margin-right: 5px;
			vertical-align: middle;
			font-weight: 700;
		}
	}
	@media(min-width: @screen-sm-min) {
		margin-left: 15px;
		margin-right: -15px;
	}
}

.header-content__title{
	color: #fff;
	font-size: 70px;
	font-weight: 300;
	min-height: 180px;
	margin-left: -8px;
	@media(min-width: @screen-sm-min) {
		font-size: 100px;
		min-height: 222px;
	}
}

.header-content__biography, .header-content__contact{
	border-top: 3px dashed #000;
	h3{
		font-size: 15px;
		line-height: 20px;
		font-weight: 700;
		color: #000;
		text-transform: uppercase;
		text-decoration: none;
		display: inline-block;
		position: relative;
    	top: -3px;
	}
	@media(min-width: @screen-sm-min) {
		margin-right: 0px;
	}
}

#biography-content, #contact-content {
	p{
		padding-bottom: 20px;
	}
}

svg{
	path{
		&:hover{
			fill: rgb(200,0,0);
		}
	}
}

.bolletje{
	.bolplus{
		display: none;
	}
	.bolmin{
		display: inline;
	}
}

.collapsed.bolletje{
	.bolplus{
		display: inline;
	}
	.bolmin{
		display: none;
	}
}

.bolplus{
	height: 20px;
	width: 20px;
	margin-right: 15px;
}

.bolmin{
	height: 20px;
	width: 20px;
	margin-right: 15px;
}

.collapse.in {
    padding-bottom: 15px;
}


