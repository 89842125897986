.post-block{
	.make-sm-column(12);
	padding-bottom: 10px;	
	p{
		display: inline-block;
		font-size: 15px;
		line-height: 25px;
	}
}

.image-content__empty{
	display: block;
	border-top: 3px dashed #000;
	height: 15px;
	@media(min-width: @screen-sm-min) {
		display: none;
	}
}

.post-block-image__thumbnail{
	height: 230px;
	background-repeat: no-repeat;
	background-position: top left;
	background-size: contain;
	text-align: right;
	margin-right: 0;
    margin-left: 0;
	@media(min-width: @screen-sm-min) {
		.make-sm-column(6);
		background-position: top right;
		float: left;
		margin-left: -15px;
		margin-right: 15px;
		border-top: 0;
	}
}

.post-block-image-content-head{
	.make-sm-column(6);
	width: 100%;
	padding: 0;
	a{
		font-size: 15px;
		line-height: 25px;
		color: #000;
		text-decoration: none;
	}
	@media(min-width: @screen-sm-min){
		margin-left: 15px;
    	margin-right: -15px;
	}
}

.post-block-image-content{
	.make-sm-column(6);
	width: 100%;
	padding: 0;
	a{
		font-size: 15px;
		line-height: 25px;
		color: #000;
		text-decoration: none;
		&:hover{
			color: rgb(200,0,0);
		}
		&:before{
			content:"\2192 ";
			margin-right: 5px;
			vertical-align: middle;
			font-weight: 700;
		}
	}
	@media(min-width: @screen-sm-min) {
		margin-left: 15px;
    	margin-right: -15px;
	}
}

.image-content__number{
	color: #fff;
	font-size: 70px;
	line-height: 90px;
	font-weight: 300;
	padding-top: 5px;
	padding-bottom: 5px;
	&:hover{
		color: rgb(200,0,0);
		text-decoration: none;
	}
	@media(min-width: @screen-sm-min) {
		border-top: 3px dashed #000;
		font-size: 100px;
	}
}

.image-content__title{
	color: #000;
	font-weight: 700;
	font-size: 15px;
	line-height: 25px;
}

.collapsed{
	&:hover{
		color: rgb(200,0,0);
		.image-content__number{
			color: rgb(200,0,0);
		}
		.image-content__title{
			color: rgb(200,0,0);
		}
		.quote-content__number{
			color: rgb(200,0,0);
		}
		.quote-content__title{
			color: rgb(200,0,0);
		}
		.quote-content__sentence{
			color: rgb(200,0,0);
		}
		svg{
			path{
				fill: rgb(200,0,0);
			}
		}
		h3{
			color: rgb(200,0,0);
		}
	}
}

.readmore{
	font-style: italic;
	position: relative;
	top: -4px;
}

.post-block-quote-content{
	a{
		font-size: 15px;
		line-height: 25px;
		color: #000;
		text-decoration: none;
	}
}

.quote-content__empty{
	display: none;
	@media(min-width: @screen-sm-min) {
		.make-sm-column(6);
		border-top: 3px dashed #000;
		padding: 0;
		margin-left: -15px;
    	margin-right: 15px;
		display: block;
	}
}

.quote-content-total__empty{
	.make-sm-column(6);
	padding: 0;
}

.quote-content__number{
	.make-sm-column(6);
	text-align: left;
	color: #fff;
	font-size: 15px;
	line-height: 25px;
	font-weight: 300;
	border-top: 3px dashed #000;
	padding: 0;
	padding-top: 10px;
	@media(min-width: @screen-sm-min) {
		margin-left: 15px;
    	margin-right: -15px;
	}
}

.quote-content__title{
	font-size: 70px;
	line-height: 90px;
	font-weight: 300;
	font-style: italic;
	padding-bottom: 25px;
	white-space: nowrap;
 	overflow: hidden;
  	width: 100%;
	text-transform: lowercase;
	padding-left: 15px;
  	@media(min-width: @screen-sm-min) {
  		text-align: center;
  		font-size: 100px;
  	}
}

.block-empty-quote{
	display: none;
	.make-sm-column(6);
	@media(min-width: @screen-sm-min) {
		display: block;
	}
}

.quote-content__sentence{
	.make-sm-column(6);
	font-size: 15px;
	line-height: 25px;
	font-weight: 600;
	font-style: italic;
	padding: 0;
	@media(min-width: @screen-sm-min) {
	    margin-left: 15px;
    	margin-right: -15px;
    }
}

#post-content{
	margin-top: 15px;
}

.bolletje{
	.readmore{
		display: none;
	}
}

.collapsed.bolletje{
	.readmore{
		display: inline;
	}
}


.panel-collapse{

	@media(min-width: @screen-sm-min) {
	   	width: 370px;
    }
	@media(min-width: @screen-md-min) {
	   	width: 470px;
    }
}
